.carousel {
  margin-bottom: 20px;
  position: relative;
  height: auto;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: '0px 0px 9px rgb(0 0 0 / 48%)';
  @media (min-width: 1200px) {
    height: 25vh;
  }
  &.banner {
    @media (min-width: 1200px) {
      height: auto;
    }
    @media (min-width: 2000px) {
      height: 30vh !important;
      img {
        height: 30vh !important;
        max-height: none !important;
      }
    }
  }
  .carousel-inner {
    width: 100%;
    height: 100%;
    .carousel-item {
      width: 100%;
      height: auto;
      max-height: 250px;
      img {
        object-fit: cover;
        width: 100%;
        height: auto;
        max-height: 250px;
      }
    }
  }
  &:after {
    content: '';
    display: block;
  }
}

.show-preview {
  @media (min-width: 768px) {
    max-height: 330px !important;
    height: 330px !important;
  }
}
.show-carousel {
  @media (min-width: 768px) {
    height: 320px !important;
  }
}
