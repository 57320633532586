.card-container {
  height: 390px;
  overflow: hidden;
  border-radius: 10px !important;
  img {
    object-fit: cover;
    width: 100%;
    height: 185px;
    @media (max-width: 450px) {
      height: 150px;
    }
  }
  .card-body {
    padding: 1rem 1rem 0 1rem;

    .card-title {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 15px;
    }
    &.body-product {
      margin-left: 20px;
    }
    &.body-challenge {
      padding: 0.9rem 1rem 0 1rem;
    }

    @media (max-width: 450px) {
      .card-title {
        font-size: 13px;
      }
      &.body-product {
        margin-left: 8px;
      }
      &.body-challenge {
        padding: 0.6rem 0.6rem 0 0.6rem;
      }
    }
  }
  &.card-product {
    height: 350px;

    .card-title {
      -webkit-line-clamp: 3;
    }
    @media (max-width: 450px) {
      height: 300px;
    }
    @media (max-width: 520px) {
      .card-title {
        -webkit-line-clamp: 2;
      }
    }
  }
  &.card-challenge {
    height: 330px !important;

    @media (min-width: 991px) {
      &.menu-type-0 {
        height: 310px !important;
      }
    }
    @media (max-width: 450px) {
      height: 250px !important;

      &.menu-type-0 {
        height: 250px !important;
      }
    }
  }
}

.mobile-columns {
  @media (max-width: 450px) {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}

.circle-points {
  @media (max-width: 370px) {
    margin-left: 5px;
  }
}

.category-product {
  width: 180%;
  transform: rotate(270deg);
  top: -19px;
  right: 16px;
  @media (max-width: 450px) {
    font-size: 12px;
    right: 19px;
  }
}

.container-vertical-line {
  width: 280px;
}

.margin-card-products {
  @media (min-width: 1200px) {
    margin: 0 70px;
  }
}

.point-title {
  text-align: left;
  font-size: 14px !important;
  white-space: nowrap;
  width: 99%;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 450px) {
    font-size: 12px !important;
  }
}

.points {
  font-size: small;
}

.redeem-btn {
  text-align: center;
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 18px;

  &.btn-products {
    padding: 0 8% 15px 17% !important;
  }
  &.btn-challenges {
    padding: 0 12% 7% !important;
  }
}

.product-info-container-tab {
  border-radius: 10px !important;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.14);
  padding: 10px;
  margin-bottom: 30px;
}
.tab-content {
  padding-inline: 10px;
}

.product-order-container {
  @media (max-width: 575px) {
    margin-block: 10px;
  }
}
.product-multiselect-container {
  @media (max-width: 575px) {
    margin-block: 10px;
  }
}
.container-categories {
  display: block;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 0.7rem;
    height: 0.6rem;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f4f4f4;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #dcdcdc;
  }
}
.box-categories {
  display: flex;

  .container-btn {
    margin-right: 0.8rem;
    width: 100px;

    &.disabled {
      opacity: 0.5;

      .btn-category {
        cursor: default !important;
      }
    }

    .btn-category {
      border: none;
      padding: 0;
      width: 100px;
      height: 100px;
      background-color: transparent;
      border-radius: 50%;

      &:hover {
        opacity: 0.8;
      }

      .img-category {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
      .box-color-category {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        border-radius: 50%;

        p {
          font-weight: 600;
          font-size: 2rem;
          color: white;
          margin: 0;
        }
      }
    }
  }
}

.skeleton-container {
  border: none;
  padding: 0;
  min-width: 100px;
  height: 100px;
  background-color: transparent;
  border-radius: 50%;
  background: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200% 100%;
  animation: skeletonAnimation 1.5s infinite;
  overflow-y: hidden;
  margin-bottom: 20px;

  .btn-category {
    cursor: default !important;
  }
}

@keyframes skeletonAnimation {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
