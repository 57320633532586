.table-avatar {
  width: 40px;
  height: 40px;
}

.table--tbody-ranking > tr > td {
  vertical-align: middle;
  padding-block: 12px;
  height: 78px;
}

.table--tbody-ranking {
  tr:nth-child(1) td {
    border: none;
  }
  tr {
    td:nth-child(1) {
      width: 10%;
      text-align: center;
      font-weight: bold;
    }
    td:nth-child(2) {
      width: 10%;
    }
    td:nth-child(4) {
      width: 25%;
    }
  }
}

.slide-dashboard {
  img {
    object-fit: cover !important;
    height: 100% !important;
  }

  @media (min-width: 1200px) {
    min-height: 320px;

    img {
      min-height: 320px;
    }
  }
  @media (min-width: 2000px) {
    height: 44.5vh;
    img {
      max-height: none !important;
    }
  }
  &.dashboard2 {
    img {
      border-radius: 20px;
    }
    @media (min-width: 2000px) {
      height: 52vh;
      img {
        max-height: none !important;
      }
    }
  }
}

.font-bold {
  font-weight: bold;
}
