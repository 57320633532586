.content-benefits {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));

  .container-benefit-card {
    width: 100%;
  }

  .benefit-card {
    border-radius: 10px !important;
    height: 250px;
    margin: 0 15px;

    &.category {
      height: 270px;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: auto;
      max-height: 100px;
    }
    .card-title {
      font-size: 1.2rem;
      margin: 13px 15px 5px 15px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      &.category {
        margin: 20px 0 15px 0;
      }
    }
    .card-description {
      color: black;
      padding: 0 15px;
      margin: 8px 0 13px 0;
      height: auto;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .see-more {
      text-align: right;
      flex: 1 1 auto;
      justify-content: flex-end;
      align-items: flex-end;
      padding-right: 10%;
    }
  }

  @media (max-width: 575px) {
    grid-template-columns: 47% 47%;
    gap: 20px;

    .benefit-card {
      margin: 0;
      height: 220px;
    }
  }
}
