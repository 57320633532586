.challenge-index {
    .dropdown-menu {
        width: 200px !important;
        .dropdown-basic-item {
            text-transform: capitalize;
        }
    }
}

.text-empty {
    font-size: 1.2rem;
}

.container-loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 280px);
    height: 50vh;
    opacity: 0.9;
    background-color: rgba(255, 255, 255, 0.514);
    z-index: 999;

    @media (max-width: 991px) {
        width: 90%;
    }
    @media (max-width: 600px) {
        height: 100vh;
        top: 0;
    }
}

.challange-info-container-tab{
    border-radius: 10px !important;
    box-shadow: 1px 1px 8px rgba(0,0,0,0.14);
    padding: 10px 0;
    margin-bottom: 30px;
}
.tab-content{
    overflow-x: hidden;
    @media (max-width: 650px) {
        padding-inline: 5px;
        
        h2 {
            span {
                font-size: 1.3rem !important;
            }
        }
        p {
            span {
                font-size: 1rem !important;
            }
        }
        img {
            width: 100% !important;
            height: auto !important;
        }
    }
}
  
.card-description{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 3px;
    font-size: .8rem;
}

.circle-progress {
    position: relative;
    width: 200px;
    height: 200px;
}
  
.svg {
    transform: rotate(-90deg);
}
  
.circle-bg {
    stroke: #dadada;
}
  
.circle {
    stroke: #FFBE1E;
    transition: stroke-dashoffset 0.35s;
}
  
.percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #4285f4;
    background-color: #E6E9EB;
    border-radius: 50%;
    height: 145px;
    width: 145px;
    font-family: Averta-Bold;
    p {
        margin: 0;
    }
}

.font-bold {
    font-family: Averta-Bold;
}
  
.gap-40 {
    gap: 40px;
}

.overflow-x {
    overflow-x: auto;
    width: 100%;
}

.link {
    text-decoration: underline;
    cursor: pointer;

    @media (max-width: 590px){
        font-size: 14px;
    }
}

.not-underline {
    transition: 0.2s all;
    @media (min-width: 650px) {
        &:hover {
            text-decoration: none;
            transform: scale(1.1);
            transition: 0.2s all;
        }
    }
}

.gap-50 {
    gap: 50px;
}

.text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.container-highlight {
    .slick-track {
        display: flex;        
        @media (min-width: 1024px) {
            gap: 5vw;
        }
        @media (min-width: 1325px) {
            gap: 0px;
        }
    }
    .slick-next:before,
    .slick-prev:before {
        color: rgb(126, 126, 126);
    }
    .slick-next {
        right: 0 !important;
        z-index: 10000;
    }
    .slick-prev {
        left: 0 !important;
        z-index: 10000;
    }
    .slick-slide {
        > div:first-child {
            display: flex;
            justify-content: center;
        }
    }
}

.card-highlight {
    transition: 0.2s all;
    background-color: white;
    border: 1px solid #ccc;
    height: 440px;
    width: 310px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    border-radius: 12px;

    .title-box {
        min-height: 45px;
        width: 100%;
        background-color: rgb(6, 6, 182);
        display: flex;
        justify-content: center;
        align-items: center;

        .title {
            font-size: 1rem;
            color: white;
            text-transform: uppercase;
            margin-bottom: 0;
            font-family: Averta-Bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 80%;
            text-align: center;
        }
    }
    .text-info {
        font-size: 14px;
        margin-bottom: 0;
    }
    .text-size {
        font-size: .9rem;
        padding: 0 20px;
        margin-top: 10px;
    }
    .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #E6E9EB;
        height: 190px;
        width: 190px;
        border-radius: 50%;

        img {
            width: 60px;
            height: 60px;
        }
        .number {
            margin: 0;
            font-size: 2.8rem;
            font-family: Averta-Bold;
        }
        p {
            margin: -10px;
            font-size: 1.2rem;
            font-family: Averta-Bold;
        }
    }

    @media (min-width: 991px) {
        transform: scale(0.9);
    }
}

.card-challenge-list {
    border: 1px solid #e3e0e0;
    border-radius: 10px;
    width: 100%;
    height: 170px;
    padding: 20px 30px;
    display: flex;
    align-items: center;
}

.img-list {
    min-width: 110px;
    min-height: 110px;
    background-color: #E6E9EB;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 70px;
        height: 70px;
    }
}
.title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.box-ranking {
    display: flex;
    padding: 5px;
    width: 140px;
    background-color: #ccc;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    font-size: 1rem;

    p {
        color: white;
        margin: 0;
    }
}
.box-progress {
    @media (min-width: 1200px){
        max-width: 75%;
        min-width: 75%;
    }
}
.text-size {
    font-size: 1.2rem;
}
.text-summary {
    @media (max-width: 590px){
        font-size: 12px;
    }
}
.text-percentage {
    font-size: 12px;
}
.progress-bar-list {
    height: 20px;
    width: 100%;
    background-color: #ccc;
    border-radius: 8px;
    display: flex;
    align-items: center;
    border: none;
}
.progress-list {
    height: 20px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.challenge-show {
    .img-list {
        min-width: 80px;
        min-height: 80px;
        img {
            width: 50px;
            height: 50px;
        }
    }
}
@media (max-width: 1095px){
    .box-progress {
        max-width: 77%;
        min-width: 77%;
    }
}
@media (max-width: 800px){
    .card-challenge-list {
        padding: 10px 15px;
    }

   .box-progress {
       max-width: 78%;
    }
}
@media (max-width: 590px){
    .card-challenge-list {
        padding: 5px 10px;
        height: auto;
    }

    .img-list {
        min-width: 70px;
        min-height: 70px;    
        img {
            width: 50px;
            height: 50px;
        }
    }
    .title {
        max-width: 90% !important;
        font-size: 1rem;

        &.title-progress {
            max-width: 95% !important;
        }
        &.title-accumulation {
            max-width: 90% !important;
        }
    }
    .box-progress {
        max-width: 70%;
        min-width: 70%;
    }
    .box-ranking {
        width: 90px;
        font-size: .9rem;
    }
    .box-info {
        font-size: 12px;
    }
    .title-show {
        font-size: 1.4rem;
    }
    .description-show {
        font-size: 15px;
    }
    .tab-link {
        font-size: 13px !important;
        padding: 0.5rem 0 !important;
    }
}
@media (max-width: 390px){
    .title {
        max-width: 85% !important;
        font-size: 1rem;

        &.title-progress {
            max-width: 80% !important;
        }
        &.title-accumulation {
            max-width: 85% !important;
        }
    }
}
@media (max-width: 380px){
    .title {
        max-width: 80% !important;
        font-size: 1rem;

        &.title-progress {
            max-width: 95% !important;
        }
        &.title-accumulation {
            max-width: 75% !important;
        }
    }
}