.basic-card {
  padding: 22px 26px !important;
  border-radius: 10px !important;
  .card-body {
    padding: 0;
    font-size: 17px;
  }
  &.min-h {
    height: 180px !important;
    @media (min-width: 680px) {
      min-height: 150px !important;
    }
    @media (min-width: 1440px) {
      height: 150px !important;
    }
  }
}
.profile-img {
  @media (min-width: 1500px) {
    display: flex;
    justify-content: flex-end;
  }
}

.scroll-x {
  display: block;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 0.7rem;
    height: 0.6rem;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f4f4f4;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #dcdcdc;
  }
}

.bg-datatable {
  display: flex;
  justify-content: center;
  box-shadow: 1px 1px 5px 1px #00000022;
  padding: 15px;
  border-radius: 10px;
}

.text-meta-prize {
  font-weight: 700;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    display: inline-block;
    padding: 5px 15px;
    border-radius: 5px;
    color: white;
    box-shadow: 1px 2px 2px 0 #ccc;
    margin-top: 10px;
  }
}

.logo-rintipet {
  height: 100%;
  max-width: 600px;
  width: 100%;
  margin-left: -5px;
  margin-top: 10px;
  max-height: 90px;
}

.text-md {
  font-size: 1.1rem;
}
