.table-avatar {
  width: 40px;
  height: 40px;
}

.title-td-ranking {
  text-align: center;
  font-weight: bold;
}

.without-padding-r {
  padding-right: 0px !important;
}

.container-ranking-row {
  padding: 10px 4px;
}
