.containerSpinnerLoad {
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 300px;
}
.centerview {
  width: 100%;
  min-height: 300px;
  height: 88vh;
  top: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hover-dropzone {
  .containerSpinnerLoad {
    min-height: auto !important;
    .centerview {
      width: 100% !important;
      min-height: auto !important;
      height: auto !important;
      bottom: 0;
    }
  }
}
