//
// -- Ejemplo --
//

// h1 {
//   color: $blue;
// }

@font-face {
  font-family: 'Averta-Regular';
  src: local('AvertaRegular'), url('./fonts/Averta-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Averta-Bold';
  src: local('AvertaBold'), url('./fonts/Averta-Bold.woff') format('woff');
}

html {
  position: relative;
  min-height: 100%;
}

body {
  height: 10px;
  padding-top: 60px;
  background-color: $primary-bg;
  font-family: Averta-Regular !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Averta-Bold !important;
}

.text-primary {
  color: $primary !important;
}

.text-secondary {
  color: $secondary !important;
}

.text-white {
  color: $white !important;
}

.text-black {
  color: $black !important;
}

.custom-title {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $secondary;
  }
}

.text-md {
  font-size: 1rem;
  font-family: Averta-Bold !important;
}

.text-h4 {
  font-size: 1.5rem;
  font-family: Averta-Bold !important;
}

.bg-login {
  background-image: $bg-login;
  background-size: cover;
  min-height: 100vh;
  overflow-x: hidden;

  a {
    color: $white;
    &:hover {
      color: darken($primary, 20%);
    }
  }
  label {
    color: $primary;
  }
  .btn-primary {
    background-color: $primary;
    border-color: $primary;
  }
  .btn-red {
    background-color: red;
    border-color: red;
  }
  .btn-secondary {
    background-color: $secondary;
  }
}

.header {
  width: 100%;
  height: 40%;
  position: absolute;
  background-color: $primary;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 50% 20%;
  border-bottom-right-radius: 50% 20%;
  z-index: -1;
}

.d-inline-block {
  width: 100px;
}

.logo-footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.logo-footer {
  width: 100px !important;
}

.nav-container {
  height: calc(100vh - 60px);
  display: flex;
  position: relative;
  overflow: hidden;

  &.public {
    margin-top: 3.75rem;
  }
}

.navbar-menu {
  min-width: 100%;
  height: 50px !important;

  @media (max-width: 991px) {
    display: none;
  }
  .pro-sidebar-inner {
    margin-top: 0;
    padding: 0;
    height: 100px;
    display: flex;
    justify-content: center;

    .pro-sidebar-layout {
      overflow-y: none !important;
      overflow-x: auto !important;
      justify-content: center;

      &::-webkit-scrollbar {
        height: 0.3rem;
      }
      .pro-menu {
        padding: 0;

        .pro-menu-item {
          padding: 0;
          margin: 0 20px 0 0;
          .suffix-wrapper {
            margin-top: -5px;
            margin-left: 5px;
          }
          .pro-inner-item {
            padding: 5px !important;
          }
        }
      }
    }
  }

  ul {
    display: flex !important;
  }
}

.hidden-sidebar {
  @media (min-width: 991px) {
    display: none;
  }
}

.container-fluid {
  padding-top: 35px;
  height: calc(100vh - 60px);
  overflow: scroll;
}

.full-height {
  height: calc(80vh);
}

.carousel-show {
  position: relative;
  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &:after {
    content: '';
    padding-bottom: 40%;
    display: block;
  }
}
.custom-data-table {
  min-height: 350px;
}

.avatar {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border: 7px solid;
  border-color: $primary;
}

.btn {
  @include transition(
    opacity 0.3s ease,
    background-color 0.3s ease,
    color 0.3s ease,
    border-color 0.3s ease
  );

  &:hover,
  &:focus {
    filter: alpha(opacity = 80);
    opacity: 0.8;
  }
}

.btn-primary {
  background-color: $primary;
}

.btn-tabs {
  background-color: transparent;
  border: 2px solid $primary;
  color: $primary;
  margin-left: 5px;
  padding: 5px 30px;
  min-width: 140px !important;

  &.active-btn {
    background-color: $primary;
    color: $white;
  }
}

.btn-title {
  letter-spacing: 2px;
  line-height: 250%;
}

.btn-circle.btn-xl {
  border-radius: 15px;
  font-size: 12px;
}

.card {
  border-width: 0;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.card-body-text {
  color: $primary;
}

.datepicker-ranking {
  background-color: $primary-bg;
}

.card-img-top {
  height: 0;
  width: 100%;
  padding-bottom: calc(100% / (var(--aspect-ratio)));
}

.img-card-style {
  height: auto !important;
  max-height: 130px !important;

  @media (max-width: 450px) {
    max-height: 70px !important;
  }
  @media (max-width: 371px) {
    max-height: 45px !important;
  }
}

[style^='--aspect-ratio']::before {
  content: '';
  display: inline-block;
  width: 1px;
  height: 0;
  padding-bottom: calc(100% / (var(--aspect-ratio)));
}

.card-primary-bg {
  background-color: $primary;
  box-shadow: 3.09944px 3.09944px 23.2458px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  color: $primary-bg;
}

.card-secondary-bg {
  background-color: $secondary;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  color: $white !important;
}

.spacer-primary {
  margin-left: 6px;
  margin-right: 6px;
  width: 2px;
  background-color: $primary;
}

.card-title {
  font-weight: bold;
  color: $primary;
}

.nn-circle {
  display: flex;
  width: 10px;
  height: 10px;
  background-color: $tertiary;
  border-radius: 50%;
}

.nn-collapsed {
  display: none;
}

.price-detail-item {
  background-color: $tertiary !important;
  color: $white;
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: column;
  text-align: center !important;
  border-width: 0 !important;
  border-right-color: $white;
  border-right-width: 1px !important;
}

.tab-container {
  display: flex;
  align-items: stretch;
}

.tab-label {
  flex: 1;
  text-align: center;
}

.list-group-item {
  background-color: transparent;
}

.testimonial-group > .row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.testimonial-group > .row {
  flex: 0 0 auto;
}

.reward-text {
  font-weight: bold;
  font-size: 0.8rem;
}

// SIDEBAR

.pro-sidebar-inner {
  margin-top: 34px;
  border-radius: 0 15px 15px 0;
  height: 92% !important;
  width: 100% !important;
  padding: 5% 10%;
  background-color: $primary !important;

  @media (max-width: 991px) {
    margin-top: 0;
    height: 93% !important;
    border-radius: 0;
    width: 270px !important;
  }
  .pro-inner-item {
    padding: 8px 35px 8px 10px !important;
  }

  .pro-sidebar-layout {
    padding-right: 10px;
    &::-webkit-scrollbar {
      width: 0.7rem;
      height: 0.6rem;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: darken($primary, 5);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: lighten($primary, 10);
    }
  }
}

.pro-menu-item {
  padding: 5% 0%;
  margin: 5px 0;
  color: $primary;
  font-family: Averta-Bold !important;
}

.pro-menu-item.active {
  background-color: $secondary;
  color: $white !important;
  border-radius: 10px;
  &:hover {
    .pro-item-content {
      color: $white !important;
    }
  }
}

.pro-item-content {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  color: $white;
  white-space: inherit !important;
  &:hover {
    color: lighten($secondary, 5%);
  }
}

// NAVBAR
.dropdown-item {
  white-space: inherit;
}
.dropdown-menu {
  width: 24vw;
  z-index: 10000;
  @media (max-width: 768px) {
    width: 75vw;
    overflow: scroll;
  }
}

.navbar {
  height: 71px;
  background-color: $primary !important;
  .navbar-brand {
    display: flex;
    width: 130px;
    margin-top: 5px;
    img {
      height: 48px;
      object-fit: contain;
    }
  }
  @media (max-width: 334px) {
    padding: 0;
  }
}

.dropdown-basic-toggle {
  display: inline-block;
  width: 90%;
  text-align: justify;
  font-size: 14px;
  font-weight: bold;
}

.dropdown-basic-menu {
  font-size: 14px;
}

.dropdown-basic-item.active {
  background-color: $gray-600;
}

.dropdown-basic-item {
  color: $gray-600;
}

.position-dropdown {
  margin-right: -14px;
}

// MARGENES
.m-t-0 {
  margin-top: 0 !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-t-50 {
  margin-top: 50px !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.m-b-50 {
  margin-bottom: 50px !important;
}

.font-w-b {
  font-weight: bold;
}

.badge-primary {
  color: $white;
  background-color: $badge-bg-primary;
  padding: 8px 10px;
  border-radius: 20px;
}

.badge-red {
  color: $badge-text-red;
  background-color: $badge-bg-red;
  padding: 8px 10px;
  border-radius: 20px;
  font-size: 14px;
}

.badge-gray {
  color: $white;
  background-color: $secondary;
  padding: 8px;
  border-radius: 20px;
  font-size: 14px;

  &.hover-badge {
    &:hover {
      color: white !important;
    }
  }
}
.custom-badge {
  span {
    background-color: white !important;
    color: $secondary !important;
    &.hover-badge {
      &:hover {
        color: $primary !important;
      }
    }
  }
}

.screen-title {
  font-weight: bold;

  &.category-name {
    @media (max-width: 575px) {
      font-size: 2rem;
    }
  }
}

.time-notification {
  font-weight: 700;
  color: $gray-900 !important;
}

.notification-container-icon {
  align-self: center;
  text-align: center;
}

.profile-dropdown-link {
  display: inline-block;
  padding: 0.4em;
}

.layout-container {
  padding-inline: 30px;
  @media (max-width: 768px) {
    padding-inline: 20px;
  }
}

.login-logo {
  width: 500px;
  max-height: 240px;

  &.logo-xl {
    max-height: 320px !important;
  }

  @media (max-width: 550px) {
    max-width: 260px;

    &.logo-xl {
      max-width: 300px !important;
    }
  }
}

.bg-color {
  background-color: $primary-bg;
}

.bg-color-primary {
  background-color: $primary;
}

.bg-color-secoundary {
  background-color: $secondary;
}

//datatable styles

.epTcix {
  background-color: $primary-bg;
}

.datatable {
  header {
    background-color: $primary-bg;
    input {
      background-color: $primary-bg;
    }
  }
}

.nav-logo {
  margin-left: 20px;
  margin-top: -8px;
}

.nav-brand {
  @media (max-width: 482px) {
    display: none !important;
  }
}
.brand-sidebar {
  @media (min-width: 483px) {
    display: none !important;
  }
}

.btn-wsp {
  background-color: #25d366;
  position: absolute;
  z-index: 1000;
  height: 62px;
  width: 62px;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
  .wsp-icon {
    color: #ffffff;
    font-size: 40px;
    margin-left: 3px;
    margin-bottom: 2px;
  }
}

.btn-link {
  color: $primary;

  &:hover {
    color: $secondary;
  }
}

// sociosreckitt
.sidebar-white {
  .pro-sidebar-inner {
    background-color: white !important;
    box-shadow: 2px 2px 10px 2px rgba(63, 63, 63, 0.1);
  }
  .pro-menu-item.active {
    background-color: $primary;
    color: white !important;
    border-radius: 10px;
    &:hover {
      .pro-item-content {
        color: $white !important;
      }
    }
    .pro-item-content {
      color: white !important;
    }
  }
  .pro-item-content {
    color: $secondary !important;
  }
}

.bg-color-my-points {
  background-color: white !important;
  padding: 12px;

  .card-body {
    border: 1px solid $primary;
    border-radius: 10px;
    padding: 0 20px;
    min-height: 150px !important;
  }
  p,
  h2 {
    color: $secondary !important;
  }
  .btn-outline-primary {
    background-color: $primary;
    color: white;
    margin-top: -8px;
  }
}

.z-index-1 {
  position: relative;
  z-index: 0;
}

.basic-card-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.active-category {
  border: 2px solid $secondary !important;
}

.dropdown {
  .btn-outline-gray.dropdown-toggle {
    color: #7b7b7b !important;
    border: 1px solid #ced4da;

    &:hover {
      background-color: transparent;
      color: $primary;
    }
    &:focus {
      border: 1px solid $primary;
      color: $primary !important;
      box-shadow: none !important;
    }
  }
}

.show {
  &.dropdown {
    .btn-outline-gray.dropdown-toggle {
      background-color: transparent !important;
      border: 1px solid $primary;
      color: $primary !important;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .dropdown-basic-item.active {
    background-color: $primary;
  }
}

.table-meta-profile {
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  font-size: 14px;

  @media (max-width: 500px) {
    margin-left: calc(90% - 150px);
  }

  thead {
    color: white;
    tr {
      th {
        .bg-secondary {
          background-color: $secondary;
          height: 50px;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 90%;
        }

        &:nth-child(1) {
          min-width: 110px;
          text-align: center;
        }
        &:nth-child(2) {
          min-width: 70px;
          text-align: center;
        }
        &:nth-child(3) {
          min-width: 70px;
          text-align: center;
        }
        &:nth-child(4) {
          min-width: 110px;
          text-align: center;
        }
        &:nth-child(5) {
          min-width: 100px;
          text-align: center;
        }
      }
    }
  }
  tbody {
    text-align: center;
    tr {
      td {
        padding: 10px 0;
        div {
          display: inline-block;
          width: 50%;

          &.left {
            border-right: 1px solid rgba(0, 0, 0, 0.12);
          }
        }
        .bg-gray-light {
          background-color: #f0f0f0;
          height: 50px;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 90%;
        }
      }
    }
  }
}

.bg-primary-color {
  background-color: $secondary;
}

.notifications-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75px;
}

.type-btn {
  &:focus,
  &:active,
  &:hover {
    background-color: $primary !important;
    cursor: default !important;
    box-shadow: none !important;
    opacity: 1;
  }
}

.bottom-logo-sidebar {
  width: 100%;
  max-height: 100px;
  object-fit: contain;
}

.img-modal {
  max-width: 100%;
}

.min-h-modal {
  min-height: 400px;
}

.full-card {
  font-size: 1.8vh;
  h5 {
    font-size: 2.3vh;
  }
  .subtitle {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 50px;
    margin-bottom: 10px;
  }
  .image-container {
    @media (min-width: 769px) {
      flex-direction: row !important;
    }
    img {
      max-height: 340px;
      min-width: 45%;
      max-width: 45%;
      object-fit: cover;

      @media (max-width: 768px) {
        min-width: 100%;
        max-width: 100%;
      }
      @media (min-width: 1400px) {
        min-height: 40vh;
      }
    }
  }
  .bg-gradient {
    background: rgb(9, 9, 121);
    background: linear-gradient(
      180deg,
      rgba(9, 9, 121, 0) 20%,
      rgba(255, 255, 255, 1) 65%
    );
    position: absolute;
    bottom: 50px;
    height: 100px;
    width: 100%;

    @media (min-width: 1400px) {
      height: 180px;
    }
  }
  .container-description {
    min-height: 130px;
    height: 45%;
    margin-bottom: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    img {
      display: none;
    }
    p {
      text-align: left;
    }
    iframe {
      max-width: 100%;
    }
  }
}

.product-show-carousel {
  height: 300px !important;
  @media (min-width: 430px) {
    height: 500px !important;
  }
  .product-show-img-carousel {
    max-height: 450px !important;
  }
}

.bg-red {
  background-color: #dc3545 !important;
}

.bg-white {
  border: 1px solid $primary;
  background-color: white !important;
  p {
    color: $secondary !important;
    &.text-h4 {
      color: $primary !important;
    }
  }
}
